window.sliderTimer13 = function sliderTimer13() {
    this.setInterval(currentSliderVRauto, 3000);
}
window.addEventListener('load', () => {
    sliderTimer13();
})
window.currentSliderVRauto = function currentSliderVRauto() {
    var firstBlock = document.getElementById('firstsector');
    var secondBlock = document.getElementById('secondsector');
    var thirdBlock = document.getElementById('thirdsector');
    var fourBlock = document.getElementById('foursector');

    var oneBlock = document.getElementById('1sector');
    var twoBlock = document.getElementById('2sector');
    var threeBlock = document.getElementById('3sector');
    var foBlock = document.getElementById('4sector');

    var isPaused = false;
    var firstdiv = document.getElementById("swipeSector").firstElementChild;
    var lastdiv = document.getElementById("swipeSector").lastElementChild;
    var listachievement = document.getElementById("swipeSector"); // Get the <ul> element to insert a new node
    lastdiv.insertAdjacentElement("afterend", firstdiv);
    var idfourfeatures = listachievement.children[0].id;
    if (!window.isPaused) {

        if (idfourfeatures === "firstsector") {
            firstBlock.style.display = 'flex';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'none';

            oneBlock.classList.add('dotredactive');
            twoBlock.classList.remove('dotredactive');
            threeBlock.classList.remove('dotredactive');
            foBlock.classList.remove('dotredactive');



        } else if (idfourfeatures === "secondsector") {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'flex';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'none';

            oneBlock.classList.remove('dotredactive');
            twoBlock.classList.add('dotredactive');
            threeBlock.classList.remove('dotredactive');
            foBlock.classList.remove('dotredactive');



        } else if (idfourfeatures === "thirdsector") {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'flex';
            fourBlock.style.display = 'none';

            oneBlock.classList.remove('dotredactive');
            twoBlock.classList.remove('dotredactive');
            threeBlock.classList.add('dotredactive');
            foBlock.classList.remove('dotredactive');



        } else if (idfourfeatures === "foursector") {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'flex';

            oneBlock.classList.remove('dotredactive');
            twoBlock.classList.remove('dotredactive');
            threeBlock.classList.remove('dotredactive');
            foBlock.classList.add('dotredactive');



        }
    }
}
window.currentSliderVR = function currentSliderVR(id) {
    var firstBlock = document.getElementById('firstsector');
    var secondBlock = document.getElementById('secondsector');
    var thirdBlock = document.getElementById('thirdsector');
    var fourBlock = document.getElementById('foursector');

    var oneBlock = document.getElementById('1sector');
    var twoBlock = document.getElementById('2sector');
    var threeBlock = document.getElementById('3sector');
    var foBlock = document.getElementById('4sector');
    var isPaused = false;
    window.isPaused = true;

    setTimeout(() => {
        window.isPaused = false;
    }, 15000);
    if (id === '1sector') {
        firstBlock.style.display = 'flex';
        secondBlock.style.display = 'none';
        thirdBlock.style.display = 'none';
        fourBlock.style.display = 'none';

        oneBlock.classList.add('dotredactive');
        twoBlock.classList.remove('dotredactive');
        threeBlock.classList.remove('dotredactive');
        foBlock.classList.remove('dotredactive');



    } else if (id === '2sector') {
        firstBlock.style.display = 'none';
        secondBlock.style.display = 'flex';
        thirdBlock.style.display = 'none';
        fourBlock.style.display = 'none';

        oneBlock.classList.remove('dotredactive');
        twoBlock.classList.add('dotredactive');
        threeBlock.classList.remove('dotredactive');
        foBlock.classList.remove('dotredactive');



    } else if (id === '3sector') {
        firstBlock.style.display = 'none';
        secondBlock.style.display = 'none';
        thirdBlock.style.display = 'flex';
        fourBlock.style.display = 'none';

        oneBlock.classList.remove('dotredactive');
        twoBlock.classList.remove('dotredactive');
        threeBlock.classList.add('dotredactive');
        foBlock.classList.remove('dotredactive');



    } else if (id === '4sector') {
        firstBlock.style.display = 'none';
        secondBlock.style.display = 'none';
        thirdBlock.style.display = 'none';
        fourBlock.style.display = 'flex';

        oneBlock.classList.remove('dotredactive');
        twoBlock.classList.remove('dotredactive');
        threeBlock.classList.remove('dotredactive');
        foBlock.classList.add('dotredactive');



    }
}
StartSwipeFeature();

function StartSwipeFeature() {
    var mySwipeS = document.getElementById('swipeSector');
    if (mySwipeS) {
        var mySwipeeS = new Hammer(mySwipeS, {
            touchAction: "pan-y"
        });

        mySwipeeS.get('pan').set({
            direction: Hammer.DIRECTION_ALL
        });

        var idS = '';


        mySwipeeS.on("swipeleft swiperight", function (ev) {
            var firstBlocksector = document.getElementById('firstsector');
            var secondBlocksector = document.getElementById('secondsector');
            var thirdBlocksector = document.getElementById('thirdsector');
            var fourBlocksector = document.getElementById('foursector');


            var oneBlocksector = document.getElementById('1sector');
            var twoBlocksector = document.getElementById('2sector');
            var threeBlocksector = document.getElementById('3sector');
            var foBlocksector = document.getElementById('4sector');

            // console.log("HERE :: ",ev)
            console.log("HERE :: ", ev.type)
            elementt = document.getElementsByClassName("dotredactive")[0];
            idS = elementt.id;
            console.log("My iDD :: ", idS)
            if (ev.type === 'swipeleft') {

                if (idS === '1sector') {
                    firstBlocksector.style.display = 'none';
                    secondBlocksector.style.display = 'flex';
                    thirdBlocksector.style.display = 'none';
                    fourBlocksector.style.display = 'none';

                    oneBlocksector.classList.remove('dotredactive');
                    twoBlocksector.classList.add('dotredactive');
                    threeBlocksector.classList.remove('dotredactive');
                    foBlocksector.classList.remove('dotredactive');

                    idfourfeatures = "secondsector";

                } else if (idS === '2sector') {
                    firstBlocksector.style.display = 'none';
                    secondBlocksector.style.display = 'none';
                    thirdBlocksector.style.display = 'flex';
                    fourBlocksector.style.display = 'none';

                    oneBlocksector.classList.remove('dotredactive');
                    twoBlocksector.classList.remove('dotredactive');
                    threeBlocksector.classList.add('dotredactive');
                    foBlocksector.classList.remove('dotredactive');

                    idfourfeatures = "thirdsector";

                } else if (idS === '3sector') {
                    firstBlocksector.style.display = 'none';
                    secondBlocksector.style.display = 'none';
                    thirdBlocksector.style.display = 'none';
                    fourBlocksector.style.display = 'flex';

                    oneBlocksector.classList.remove('dotredactive');
                    twoBlocksector.classList.remove('dotredactive');
                    threeBlocksector.classList.remove('dotredactive');
                    foBlocksector.classList.add('dotredactive');

                    idfourfeatures = "foursector";


                } else if (idS === '4sector') {
                    firstBlocksector.style.display = 'flex';
                    secondBlocksector.style.display = 'none';
                    thirdBlocksector.style.display = 'none';
                    fourBlocksector.style.display = 'none';

                    oneBlocksector.classList.add('dotredactive');
                    twoBlocksector.classList.remove('dotredactive');
                    threeBlocksector.classList.remove('dotredactive');
                    foBlocksector.classList.remove('dotredactive');

                    idfourfeatures = "firstsector";


                }


            }

            if (ev.type === 'swiperight') {
                if (idS === '1sector') {
                    firstBlocksector.style.display = 'none';
                    secondBlocksector.style.display = 'none';
                    thirdBlocksector.style.display = 'none';
                    fourBlocksector.style.display = 'flex';

                    oneBlocksector.classList.remove('dotredactive');
                    twoBlocksector.classList.remove('dotredactive');
                    threeBlocksector.classList.remove('dotredactive');
                    foBlocksector.classList.add('dotredactive');

                    idfourfeatures = "foursector";

                } else if (idS === '2sector') {
                    firstBlocksector.style.display = 'flex';
                    secondBlocksector.style.display = 'none';
                    thirdBlocksector.style.display = 'none';
                    fourBlocksector.style.display = 'none';

                    oneBlocksector.classList.add('dotredactive');
                    twoBlocksector.classList.remove('dotredactive');
                    threeBlocksector.classList.remove('dotredactive');
                    foBlocksector.classList.remove('dotredactive');

                    idfourfeatures = "firstsector";

                } else if (idS === '3sector') {
                    firstBlocksector.style.display = 'none';
                    secondBlocksector.style.display = 'flex';
                    thirdBlocksector.style.display = 'none';
                    fourBlocksector.style.display = 'none';

                    oneBlocksector.classList.remove('dotredactive');
                    twoBlocksector.classList.add('dotredactive');
                    threeBlocksector.classList.remove('dotredactive');
                    foBlocksector.classList.remove('dotredactive');

                    idfourfeatures = "secondsector";


                } else if (idS === '4sector') {
                    firstBlocksector.style.display = 'none';
                    secondBlocksector.style.display = 'none';
                    thirdBlocksector.style.display = 'flex';
                    fourBlocksector.style.display = 'none';

                    oneBlocksector.classList.remove('dotredactive');
                    twoBlocksector.classList.remove('dotredactive');
                    threeBlocksector.classList.add('dotredactive');
                    foBlocksector.classList.remove('dotredactive');

                    idfourfeatures = "thirdsector";


                }

            }
        });
    }
}